import React, { useState } from 'react';
import './Pages.css';
import MyPDFViewer from '../components/PDFViewer';

const About = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="Page">
            <header className="Page-header">
                <div className="Text-Block">
                    <h1>Hi, I'm AryveN! 👋</h1>
                    <p>
                    I'm a junior developer with a passion for coding and problem-solving, skilled in C++, C#, Java, and more. My background also includes network management (CCNA certified), and I enjoy working on diverse projects that challenge my skills and allow me to grow further.<br/><br/>

                    🛠️ <b>Technologies & Tools:</b> <ul>
                        <li>Programming languages: C++, C#, Java, PHP, JavaScript, TypeScript</li>
                        <li>Web development: HTML, CSS</li>
                        <li>Networking: Cisco, Mikrotik devices (CCNA certified)</li>
                        <li>Other tools: Git, Docker, Linux, etc.</li>
                    </ul>

                    📚 <b>Currently learning:</b><ul>
                        <li>Studying Software Engineering at university</li>
                        <li>Exploring advanced C#/.NET technologies</li>
                        <li>Delving into design patterns and clean code practices</li>
                    </ul>

                    🌱 <b>Open to:</b><br/>
                    Collaborating on interesting open-source projects<br/>
                    Learning new technologies and improving existing skills<br/>
                    <br/>
                    📜 <a href="/zivotopis_2024_2.pdf"><b>Curriculum vitæ</b></a>

                    <button onClick={toggleDropdown} className="dropdown-arrow">
                        {isOpen ? '▼' : '▶'}
                    </button>
                    {isOpen && (
                        <div className="pdf-container">
                        <MyPDFViewer pdfUrl="/zivotopis_2024_2.pdf" />
                        </div>
                    )}
                    </p>
                </div>
            </header>
        </div>
    );
};

export default About;